import ShopListing from '@components/ShopListing';
import ComponentNotFound from './component_not_found';

const ComponentList = {
  shoplocal_shops: ShopListing,
};

const Components = (type) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound;
  }
  return ComponentList[type];
};

export default Components;
