import React from 'react';
import Link from '@utility/Link';
import cn from 'classnames';
import ProductCard from '@components/ProductCard';
import KeyLine from '@components/KeyLine';
import ReactMarkdown from 'react-markdown/with-html';
import MapIcon from '@icons/MapIcon';
import ArrowUpRightIcon from '@icons/ArrowUpRightIcon';
import styles from './styles';

const Shop = ({ blok, index }) => {
  const blokObj = JSON.parse(blok);
  return (
    <div className="pt-8 md:pt-48">
      <KeyLine className="mb-12 md:mb-10" />
      <div className="row mb-16 md:mb-48">
        <sup className="inline md:hidden type-1240">{index}</sup>
        <div className="col-8 md:col-6 mb-14 md:mb-0">
          <sup className="hidden md:inline type-1240">{index}</sup>
          <h3 className="inline-block type-scaling-6050 md:pl-1 pb-5">
            {blokObj.title}
          </h3>
        </div>

        <div className="col-12 md:offset-1 md:col-5">
          <div className="flex justify-end mr-8 mb-16 md:mb-0 md:-mt-6">
            {!!blokObj.map_url && !!blokObj.map_url.url && (
              <div
                className={cn({
                  'pr-18 md:pr-20 py-4 md:py-6 border-r-2':
                    !!blokObj.visit_url && !!blokObj.visit_url.url,
                })}
              >
                <Link
                  to={blokObj.map_url.url}
                  className="type-scaling-6040-small relative"
                  styled={false}
                >
                  <span className="link">Map</span>
                  <MapIcon className="relative mb-12" />
                </Link>
              </div>
            )}

            {!!blokObj.visit_url && !!blokObj.visit_url.url && (
              <div className="pl-10 md:pl-12 py-4 md:py-6">
                <Link
                  to={blokObj.visit_url.url}
                  className="type-scaling-6040-small relative"
                  styled={false}
                >
                  <span className="link">Visit</span>
                  <ArrowUpRightIcon />
                </Link>
              </div>
            )}
          </div>
        </div>
        <div className="col-12 md:col-6">
          <div className="type-1640 pb-2 md:pb-6">Overview</div>
          {!!blokObj.description && (
            <ReactMarkdown
              source={blokObj.description}
              className={styles.description}
              escapeHtml={false}
            />
          )}
        </div>
      </div>
      <div className="row">
        {!!blokObj.products && !!blokObj.products[0] && (
          <div className="col-10 md:offset-1 md:col-5">
            <ProductCard
              product={blokObj.products[0]}
              parallax={['200px', '-100px']}
              mobileParallax={['30px', '-30px']}
            />
          </div>
        )}
        {!!blokObj.products && !!blokObj.products[1] && (
          <div className="offset-4 col-8 md:offset-2 md:col-3 md:-mt-24">
            <ProductCard
              product={blokObj.products[1]}
              parallax={['-150px', '100px']}
              mobileParallax={['30px', '-30px']}
            />
          </div>
        )}
        {!!blokObj.products && !!blokObj.products[2] && (
          <div className="col-8 md:offset-7 md:col-3 md:-mt-24">
            <ProductCard
              product={blokObj.products[2]}
              parallax={['-100px', '150px']}
              mobileParallax={['30px', '-30px']}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Shop;
