import React from 'react';
import Components from '@components/components.js';
import CategoryListing from '@components/CategoryListing';
import ShopListing from '@components/ShopListing';
import Seo from '@utility/Seo';

const HomePage = ({ blok, blok: { seo, title, description }, pageContext }) => {
  return (
    <>
      <Seo
        title={seo.title || title || pageContext.story.name || ''}
        description={seo.description || description || ''}
        image={seo.og_image || ''}
      />
      <CategoryListing blok={blok} />
      <ShopListing />
      {/* {props.blok.body &&
        props.blok.body.map((blok) =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
          }),
        )} */}
    </>
  );
};

export default HomePage;
