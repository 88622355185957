import React from 'react';
import Components from '@components/components';
import HomePage from '@templates/HomePage';

export default function templating(contentType, content, context) {
  switch (contentType) {
    case 'shoplocal_page':
      return (
        <HomePage
          blok={{ key: content._uid, ...content }}
          pageContext={context}
        />
      );
    default:
      return React.createElement(Components(content.component), {
        key: content._uid,
        blok: content,
      });
  }
}
