import React from 'react';
import PropTypes from 'prop-types';
import ScrollInView from '@utility/ScrollInView';
import styles from './styles';

const KeyLine = ({ height, className }) => (
  <ScrollInView className="row">
    <div className="col-12">
      <div className="overflow-hidden">
        <hr
          className={`${styles.keyLine} ${className}`}
          style={{ height: `${height}px` }}
        />
      </div>
    </div>
  </ScrollInView>
);

KeyLine.propTypes = {
  height: PropTypes.number,
  className: PropTypes.string,
};

KeyLine.defaultProps = {
  height: 2,
  className: '',
};

export default KeyLine;
