import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import styles from './styles';

const ArrowDownIcon = ({ inset }) => {
  return (
    <svg
      className={styles.iconArrowDown}
      width="20"
      height="24"
      viewBox="0 0 20 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 14L18.607 12.57L11 20.15L11 -3.93402e-07L9 -4.80825e-07L9 20.15L1.427 12.57L-6.11959e-07 14L10 24L20 14Z"
        className="fill-current"
      />
    </svg>
  );
};

ArrowDownIcon.propTypes = {
  inset: PropTypes.bool,
};

ArrowDownIcon.defaultProps = {
  inset: false,
};
export default ArrowDownIcon;
