import React from 'react';
import cn from 'classnames';
import Shop from '@components/Shop';
import KeyLine from '@components/KeyLine';
import Parallax from '@utility/Parallax';
import ScrollInView from '@utility/ScrollInView';
import { useStaticQuery, graphql } from 'gatsby';
import styles from './styles';

const ShopListing = () => {
  const data = useStaticQuery(
    graphql`
      query {
        categories: allStoryblokEntry(
          filter: {
            published_at: { ne: null }
            field_component: { eq: "shoplocal_categories" }
          }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              uuid
              name
              slug
              content
            }
          }
        }
        shops: allStoryblokEntry(
          filter: {
            published_at: { ne: null }
            field_component: { eq: "shoplocal_shops" }
          }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              uuid
              slug
              content
            }
          }
        }
      }
    `,
  );
  const categories = data?.categories?.edges;
  const shops = data?.shops?.edges;
  const firstShop = data?.categories?.edges[0]?.node?.slug;

  const renderShopsByCategory = () =>
    categories.map(({ node: category }) => {
      const categoryContentObj = JSON.parse(category.content);
      const shopCategoryGroup = shops.filter(
        ({ node, node: { content, uuid } }, index) => {
          const contentObj = JSON.parse(content);
          return contentObj.category.slug === category.slug;
        },
      );
      if (shopCategoryGroup.length) {
        return (
          <div
            key={category.uuid}
            className="my-12 md:mt-24 md:mb-56"
            id={category.slug}
          >
            <div className="wrapper">
              <KeyLine height={4} key={`keyline-${category.slug}`} />
              <div className="row">
                <div className="col-12 flex justify-end">
                  <div className={styles.h2}>
                    <ScrollInView
                      animateStartClass="animate-fade-in-up-start"
                      animateEndClass="animate-fade-in-up-end"
                      key={category.slug}
                    >
                      <h2 className="type-scaling-20040">
                        <span className="relative mr-2 block text-right">
                          {categoryContentObj.title}
                          <sub className={styles.number}>
                            {!!shopCategoryGroup.length &&
                              shopCategoryGroup.length}
                          </sub>
                        </span>
                      </h2>
                    </ScrollInView>
                  </div>
                </div>
              </div>
            </div>
            <ul>
              <li className="wrapper">
                {shopCategoryGroup.map(({ node: shop }, i) => (
                  <Shop key={shop.uuid} blok={shop.content} index={i + 1} />
                ))}
              </li>
            </ul>
          </div>
        );
      }
    });
  return <div id="shops">{renderShopsByCategory()}</div>;
};

export default ShopListing;
