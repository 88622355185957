import React from 'react';
import PropTypes from 'prop-types';
import Parallax from '@utility/Parallax';
import Image from '@utility/Image';
import Link from '@utility/Link';
import ArrowUpRightIcon from '@icons/ArrowUpRightIcon';
import ScrollInView from '@utility/ScrollInView';
import styles from './styles';

const ProductCard = ({ product, parallax, mobileParallax }) => {
  return (
    <Parallax y={parallax} mobileY={mobileParallax} contain={false}>
      <div className="mb-12">
        <ScrollInView
          animateStartClass="animate-reveal-start"
          animateEndClass="animate-reveal-end"
        >
          <Link
            data-context="link"
            to={product.url?.url}
            styled={false}
            className={styles.image}
          >
            <Image
              image={product.image}
              maxWidth={974}
              blok={product.image}
              alt={product.label}
            />
          </Link>
        </ScrollInView>
        <Link
          to={product.url?.url}
          className="block type-2240 lg:type-2440 pt-6 pb-8 pr-12 linkThin"
        >
          {product.label}
          <ArrowUpRightIcon inset />
        </Link>
      </div>
    </Parallax>
  );
};

ProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  parallax: PropTypes.arrayOf(PropTypes.string).isRequired,
  mobileParallax: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ProductCard;
