import React from 'react';
import { navigate, useStaticQuery, graphql } from 'gatsby';
import LocationIcon from '@icons/LocationIcon';
import ArrowDown from '@components/ArrowDown';
import Parallax from '@utility/Parallax';

const CategoryListing = ({ blok }) => {
  const data = useStaticQuery(
    graphql`
      query {
        categories: allStoryblokEntry(
          filter: {
            published_at: { ne: null }
            field_component: { eq: "shoplocal_categories" }
          }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              uuid
              name
              slug
              content
            }
          }
        }
        shops: allStoryblokEntry(
          filter: {
            published_at: { ne: null }
            field_component: { eq: "shoplocal_shops" }
          }
          sort: { fields: name, order: ASC }
        ) {
          edges {
            node {
              id
              slug
              content
            }
          }
        }
      }
    `,
  );
  const categories = data?.categories?.edges;
  const shops = data?.shops?.edges;

  const categoryCount = () => {
    const categorySlugs = shops.map(({ node, node: { content } }) => {
      const contentObj = JSON.parse(content);
      const {
        category: { slug },
      } = contentObj;
      return slug;
    });

    const sortedSlugs = categorySlugs.reduce((groups, slug) => {
      if (!groups[slug]) {
        // eslint-disable-next-line no-param-reassign
        groups[slug] = [];
      }

      groups[slug].push(slug);
      return groups;
    }, {});

    return sortedSlugs;
  };

  const buttonHandler = (e, id) => {
    e.preventDefault();
    navigate(`#${id}`);
    const element = document.getElementById(id).getBoundingClientRect().top;
    window.scrollTo({
      top: element,
      behavior: 'smooth',
    });
  };

  return (
    <div className="wrapper pt-24 md:pt-48">
      <Parallax
        y={['0px', '-200px']}
        mobileY={['0px', '-100px']}
        contain={false}
      >
        <div className="row">
          <div className="col-12 py-12 type-2240">
            <LocationIcon className="inline" /> Vancouver
          </div>
        </div>
        <div className="row">
          <nav className="col-12">
            <ul>
              {categories.map(({ node, node: { content, uuid } }, index) => {
                const contentObj = JSON.parse(content);
                if (categoryCount()[node.slug]?.length) {
                  return (
                    <li
                      className="inline-block pr-4 md:pr-6 lg:pb-4"
                      key={uuid}
                    >
                      <button
                        type="button"
                        className="md:pb-1 type-scaling-6040 link linkShort inline"
                        onClick={(e) => buttonHandler(e, node.slug)}
                      >
                        {contentObj.title}
                        <sub className="ml-2 type-1240 tracking-tight">
                          {categoryCount()[node.slug].length}
                        </sub>
                      </button>
                    </li>
                  );
                }
              })}
            </ul>
          </nav>
        </div>
      </Parallax>
    </div>
  );
};

export default CategoryListing;
