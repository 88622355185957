import React from 'react';
import styles from './styles';

const MapIcon = () => {
  return (
    <svg
      className={`inline ${styles.iconLink}`}
      width="32"
      height="36"
      viewBox="0 0 32 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="fill-current" fill="#010101">
        <g className={styles.iconMapTop}>
          <path d="M16 28L9.90997 19.4C8.91315 18.2231 8.27121 16.7872 8.05892 15.2595C7.84664 13.7318 8.07273 12.1753 8.71088 10.7712C9.34902 9.36702 10.373 8.17308 11.6634 7.32839C12.9539 6.4837 14.4578 6.02302 16 6C18.1377 6.02113 20.18 6.88859 21.6794 8.41241C23.1789 9.93623 24.0133 11.9922 24 14.13C24.0008 15.9949 23.3659 17.8044 22.2 19.26L16 28ZM16 8C14.3918 8.01847 12.8567 8.67438 11.7318 9.82371C10.6068 10.973 9.98397 12.5218 9.99997 14.13C10.0067 15.5978 10.5348 17.0155 11.49 18.13L16 24.52L20.63 18C21.5101 16.9014 21.9929 15.5376 22 14.13C22.016 12.5218 21.3931 10.973 20.2682 9.82371C19.1432 8.67438 17.6081 8.01847 16 8V8Z" />
          <path
            d="M16 15C17.1046 15 18 14.1046 18 13C18 11.8954 17.1046 11 16 11C14.8954 11 14 11.8954 14 13C14 14.1046 14.8954 15 16 15Z"
            fill="#010101"
          />
        </g>
        <path d="M28 16H26V18H28V32H4V18H6V16H4C3.46957 16 2.96086 16.2107 2.58579 16.5858C2.21071 16.9609 2 17.4696 2 18V32C2 32.5304 2.21071 33.0391 2.58579 33.4142C2.96086 33.7893 3.46957 34 4 34H28C28.5304 34 29.0391 33.7893 29.4142 33.4142C29.7893 33.0391 30 32.5304 30 32V18C30 17.4696 29.7893 16.9609 29.4142 16.5858C29.0391 16.2107 28.5304 16 28 16Z" />
      </g>
    </svg>
  );
};

export default MapIcon;
