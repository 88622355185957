import React from 'react';
import PropTypes from 'prop-types';
import SbEditable from 'storyblok-react';
import Img from 'gatsby-image';
import { getFluidGatsbyImage } from 'gatsby-storyblok-image';
import getBasicImageProps from '@helpers/getBasicImageProps';

// Base image component using gatsby image
// with storyblok's image processor
const FluidImage = ({
  maxWidth,
  mobileMaxHeight,
  image,
  blok,
  alt,
  className,
  keystring,
  sizes,
  multipliers,
}) => {
  // const TransitionImage = styled(Img)`
  //   ${({ fluid }) =>
  //     Array.isArray(fluid) &&
  //     css`
  //       div:first-child {
  //         padding-bottom: ${100 / fluid[0].aspectRatio}% !important;
  //         @media ${screen.md} {
  //           padding-bottom: ${100 / fluid[1].aspectRatio}% !important;
  //         }
  //       }
  //     `}
  // `;
  // if maxHeight is defined this will force crop the image to that ratio
  if (image !== undefined || image !== '' || image !== null) {
    const imageProps = getBasicImageProps(image);
    let dimensions = 2840;
    let extension = 'png';
    // destructuring breaks in the inspector in storyblok
    if (imageProps && imageProps.extension) {
      extension = imageProps.extension;
    }
    if (imageProps && imageProps.metadata && imageProps.metadata.dimensions) {
      dimensions = imageProps.metadata.dimensions;
    }
    const finalMaxWidth =
      maxWidth > dimensions.width || extension === 'png'
        ? dimensions.width
        : maxWidth;
    const desktopFluidProps = getFluidGatsbyImage(image, {
      maxWidth: finalMaxWidth,
      sizes,
      toFormat: 'jpg',
      smartCrop: false,
      quality: null,
      multipliers,
    });
    const mobileFluidProps = getFluidGatsbyImage(image, {
      maxWidth: 1200,
      toFormat: 'jpg',
      smartCrop: false,
      quality: null,
      maxHeight: mobileMaxHeight,
      multipliers,
    });
    const generatedFluidProps = () => {
      if (mobileMaxHeight !== null) {
        return [
          { ...mobileFluidProps },
          { ...desktopFluidProps, media: '(min-width:768px)' },
        ];
      }
      return desktopFluidProps;
    };
    if (!desktopFluidProps || !mobileFluidProps || image.src !== undefined) {
      return (
        <SbEditable content={blok}>
          <p>Please add src</p>
        </SbEditable>
      );
    }
    // console.log(image);
    return (
      <div className={`w-full overflow-hidden ${className}`} key={keystring}>
        <SbEditable content={blok}>
          <Img
            fluid={generatedFluidProps()}
            key={image}
            fadeIn
            loading="lazy"
            alt={alt}
          />
        </SbEditable>
      </div>
    );
  }

  return (
    <SbEditable content={blok}>
      <p>Please upload image</p>
    </SbEditable>
  );
};

FluidImage.propTypes = {
  blok: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.array,
  ]).isRequired,
  image: PropTypes.string,
  alt: PropTypes.string,
  keystring: PropTypes.string,
  maxWidth: PropTypes.number,
  mobileMaxHeight: PropTypes.number,
  className: PropTypes.string,
  sizes: PropTypes.string,
  multipliers: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
};

FluidImage.defaultProps = {
  image: '',
  alt: '',
  keystring: '',
  maxWidth: 1320,
  mobileMaxHeight: null,
  className: '',
  sizes: '',
  multipliers: false,
};

export default FluidImage;
